import React, { useEffect, useState } from 'react';
import "./admin.css";

const EditBlog = ({ id, closePopup }) => {
  console.log("currentBlogId:", id);
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({ blogTitle: "", blogDescription: "" });

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/showblogs/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch blog');
        }
        const data = await response.json();
        setBlog(data);
        setEditForm({ blogTitle: data.blogTitle, blogDescription: data.blogDescription });
      } catch (err) {
        setError('Error fetching blog');
      }
    };

    fetchBlog();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditForm({ ...editForm, [name]: value });
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editForm),
      });

      if (!response.ok) {
        throw new Error('Failed to update blog');
      }

      const updatedBlog = await response.json();
      setBlog(updatedBlog.blog);
      alert('Blog updated successfully');
      setIsEditing(false); // Corrected to set editing mode off after successful save
      closePopup();
    } catch (err) {
      setError('Error updating blog');
    }
  };

  return (
    <div className="show-blog-popup">
      <div className="popup-inner-sb">
        <h2>My Blog</h2>
        {error && <p className="error">{error}</p>}
        {blog ? (
          isEditing ? (
            <form onSubmit={handleEditSubmit} className="edit-blog-form">
              <div className="form-group">
                <label htmlFor="blogTitle">Title</label>
                <input
                  type="text"
                  id="blogTitle"
                  name="blogTitle"
                  className='blogTitle'
                  value={editForm.blogTitle}
                  onChange={handleInputChange}
                  placeholder={blog.blogTitle}
                />
              </div>
              <div className="form-group">
                <label htmlFor="blogDescription">Description</label>
                <textarea
                  id="blogDescription"
                  name="blogDescription"
                  className='blogDescription'
                  value={editForm.blogDescription}
                  onChange={handleInputChange}
                  placeholder={blog.blogDescription}
                ></textarea>
              </div>
              <button type="submit" className="btn-submit btn w-100 btn-warning me-2 text-black" >Save</button>
              <button type="button" className="btn-cancel btn-close-sb text-black" onClick={() => setIsEditing(false)}>Cancel</button>
            </form>
          ) : (
            <>
              <h2 className="blog-title">{blog.blogTitle}</h2>
              <p className="blog-description">{blog.blogDescription}</p>
              <p className="publish-date"><strong>Published On:</strong> {new Date(blog.dateTime).toLocaleString()}</p>

              <button onClick={() => setIsEditing(true)} className="btn w-100 btn-warning me-2 text-black">Edit</button>
              <button onClick={closePopup} className="btn-close-sb text-black">Close</button>
            </>
          )
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default EditBlog;
