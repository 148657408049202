import React, { useState, useEffect } from "react";
import "./admin.css";
import NewBlog from "./NewBlog";
import Showblog from "./Showblog";
import Editblog from "./Editblog";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentBlogId, setCurrentBlogId] = useState(null);
  const [showNewBlogPopup, setShowNewBlogPopup] = useState(false);
  const [showShowBlogPopup, setShowShowBlogPopup] = useState(false);
  const [showEditBlogPopup, setShowEditBlogPopup] = useState(false);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/blogs`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  const handleDeleteBlog = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this blog?');
    if (!confirmed) return;
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/delete/${id}`,
        {
          method: 'DELETE',
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to delete the blog');
      }
  
      alert('Blog deleted successfully');
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog._id !== id));
    } catch (error) {
      console.error('Error deleting blog:', error);
      alert('An error occurred while deleting the blog');
    }
  };
  

  const openNewBlogPopup = () => setShowNewBlogPopup(true);
  const closeNewBlogPopup = () => setShowNewBlogPopup(false);

  const openShowBlogPopup = (id) => {
    setCurrentBlogId(id);
    setShowShowBlogPopup(true);
  };
  const closeShowBlogPopup = () => {
    setCurrentBlogId(null);
    setShowShowBlogPopup(false);
  };

  const openEditBlogPopup = (id) => {
    setCurrentBlogId(id);
    setShowEditBlogPopup(true);
  };
  const closeEditBlogPopup = () => {
    setCurrentBlogId(null);
    setShowEditBlogPopup(false);
  };

  return (
    <div className="container my-5">
      <h1 className="mb-4">Manage Blogs</h1>
      <div className="d-flex mb-4">
        <button className="btn btn-lg w-100 btn-primary" onClick={openNewBlogPopup}>
          Add Blog
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-dark table-striped">
          <thead>
            <tr>
              <th>S.no</th>
              <th>Title</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{blog.blogTitle}</td>
                <td>{blog.dateTime}</td>
                <td>
                  <button
                    className="btn btn-sm btn-primary me-2 text-white"
                    onClick={() => openEditBlogPopup(blog._id)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-sm btn-warning me-2 text-black"
                    onClick={() => openShowBlogPopup(blog._id)}
                  >
                    Show
                  </button>
                  <button className="btn btn-sm btn-danger text-white" onClick={() => handleDeleteBlog(blog._id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Popup for Adding New Blog */}
      {showNewBlogPopup && (
        <div className="popup-b">
          <div className="popup-inner-b">
            <NewBlog closePopup={closeNewBlogPopup} />
          </div>
        </div>
      )}

      {/* Popup for Showing Blog */}
      {showShowBlogPopup && (
        <div className="popup-b">
          <div className="popup-inner-b">
            <Showblog id={currentBlogId} closePopup={closeShowBlogPopup} />
          </div>
        </div>
      )}

      {/* Popup for Editing Blog */}
      {showEditBlogPopup && (
        <div className="popup-b">
          <div className="popup-inner-b">
            <Editblog id={currentBlogId} closePopup={closeEditBlogPopup} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Blog;
