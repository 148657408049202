import React, { useState, useEffect } from 'react';
import './Home.css'; // You can create a CSS file for styling

const Home = () => {
  const [contactsCount, setContactsCount] = useState(0);
  const [blogsCount, setBlogsCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        // Fetch contacts count
        const contactsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/contacts`);
        if (!contactsResponse.ok) {
          throw new Error('Failed to fetch contacts');
        }
        const contacts = await contactsResponse.json();
        setContactsCount(contacts.length);

        // Fetch blogs count
        const blogsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/blogs`);
        if (!blogsResponse.ok) {
          throw new Error('Failed to fetch blogs');
        }
        const blogs = await blogsResponse.json();
        setBlogsCount(blogs.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCounts();
  }, []);

  return (
    <div className="home-container">
      <h2 class="pt-4">Dashboard</h2>
        <div class="row mt-4">
          <div class="col-md-3">
            <div class="card text-center p-3">
              <h5>Total Blogs</h5>
              <h3>{blogsCount}</h3>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card text-center p-3">
              <h5>Total Projects</h5>
              <h3>5</h3>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card text-center p-3">
              <h5>Total Skills</h5>
              <h3>15</h3>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card text-center p-3">
              <h5>New Messages</h5>
              <h3>{contactsCount}</h3>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Home;
