import React, { useState } from "react";
import Blog from "./Blog";
import Contact from "./Contact";
import Home from "./Home";
import { IoNotifications } from "react-icons/io5";
import { Link } from "react-router-dom";

const Admin = () => {
  const [activeItem, setActiveItem] = useState("Home");

  const handleClick = (item) => {
    setActiveItem(item);
  };

  return (
    <div className="container-fluid">
      <div className="row w-100">
        <div className="col-md-3 d-md-block">
          <div className="position-sticky pt-3 sidebar left-nav">
            <h4 className="text-center py-3">Admin Panel</h4>
            <ul className="flex-column">
              <li>
                <Link
                  onClick={() => handleClick("Home")}
                  className={activeItem === "Home" ? "active" : "text-white"}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => handleClick("Blog")}
                  className={activeItem === "Blog" ? "active" : "text-white"}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => handleClick("Contact Us")}
                  className={activeItem === "Contact Us" ? "active" : "text-white"}
                >
                  Contacts
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-9 ms-sm-auto px-md-4 right">
          <div className="right-nav">
            {activeItem === "Blog" && <Blog />}
            {activeItem === "Contact Us" && <Contact />}
            {activeItem === "Home" && <Home />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
