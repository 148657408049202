import React, { useState } from 'react';

const NewBlog = ({ closePopup, fetchBlogs }) => {
  const [blogTitle, setBlogTitle] = useState('');
  const [blogDescription, setBlogDescription] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currentDateTime = new Date().toISOString();

    const formData = {
      blogTitle,
      blogDescription,
      dateTime: currentDateTime
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/blogs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to add blog');
      }

      setSuccessMessage('Blog added successfully');
      closePopup(); // Close the popup after successful submission

      // Fetch updated list of blogs after adding a new one
      fetchBlogs(); // This triggers a refresh of the blogs list
    } catch (error) {
      setError(error.message || 'Failed to add blog');
    }
  };

  return (
    <div className='mt-2'>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Blog Title:</label>
          <input
            type="text"
            value={blogTitle}
            onChange={(e) => setBlogTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Blog Description:</label>
          <textarea
            value={blogDescription}
            onChange={(e) => setBlogDescription(e.target.value)}
            required
          />
        </div>
        <div className='d-flex'>
          <button type="submit" className='btn w-50 m-2'>Add Blog</button>
          <button type="button" className='btn w-50 m-2' onClick={closePopup}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default NewBlog;
