import React from 'react';
import './portfolio.css';
import IMG1 from '../../assets/project image/Ecommerce.png';
import IMG2 from '../../assets/project image/netflix.png';
import IMG3 from '../../assets/project image/portfolio.png';
import IMG4 from '../../assets/project image/FB_IMG_1706552985392.jpg';
import IMG5 from '../../assets/project image/cyo.png';
import IMG6 from  '../../assets/project image/restro.png';
import IMG7 from '../../assets/project image/leave.png';
import IMG8 from '../../assets/project image/gester.jpg';

const projects = [
  {
    id: 1,
    title: "Restro",
    image: IMG6,
    github: "https://github.com/riteshpandey7272/Restro",
    demo: "http://www.restro.theritesh.in/",
  },
  {
    id: 2,
    title: "Activity Tracker",
    image: IMG7,
    github: "https://github.com/riteshpandey7272/leave-app-ication",
    demo: "https://leave.theritesh.in/login",
  },
  {
    id: 3,
    title: "Gesture To Text",
    image: IMG8,
    github: "https://github.com/riteshpandey7272/Gesture2Text",
    demo: "https://theritesh.in/project",
  },
  {
    id: 4,
    title: "E - Commerce",
    image: IMG1,
    github: "https://github.com/riteshpandey7272/E-com-frontend",
    demo: "https://theritesh.in/project",
  },
  {
    id: 5,
    title: "Netflix Clone",
    image: IMG2,
    github: "https://github.com/riteshpandey7272/netflix-clone",
    demo: "https://theritesh.in/project",
  },
  {
    id: 6,
    title: "First Portfolio",
    image: IMG3,
    github: "https://github.com/riteshpandey7272/My-Portfolio",
    demo: "https://theritesh.in/project",
  },
  {
    id: 7,
    title: "EMMPS School",
    image: IMG4,
    github: "https://github.com/riteshpandey7272/emmpsbarodih",
    demo: "https://theritesh.in/project",
  },
  {
    id: 8,
    title: "CYO Shell",
    image: IMG5,
    github: "https://github.com/riteshpandey7272/CYOshell",
    demo: "https://theritesh.in/project",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Projects</h2>
      <div className="container portfolio__container">
        {projects.map(({ id, title, image, github, demo }) => (
          <article key={id} className="portfolio__items">
            <div className="portfolio__item-image">
              <img src={image} alt={title} />
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
              <a href={github} className="btn" target="_blank" rel="noopener noreferrer">
                GitHub
              </a>
              <a href={demo} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                Live Demo
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
