import React, { useEffect, useState } from 'react';
import "./admin.css";

const Showblog = ({ id, closePopup }) => {
  const [blog, setBlog] = useState(null);
  const [error, setError] = useState(null);
  const [activeCommentIndex, setActiveCommentIndex] = useState(null); // To track which comment's replies are active

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/showblogs/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch blog');
        }
        const data = await response.json();
        setBlog(data);
      } catch (err) {
        setError('Error fetching blog');
      }
    };

    fetchBlog();
  }, [id]);

  const toggleReplies = (index) => {
    setActiveCommentIndex(prevIndex => prevIndex === index ? null : index);
  };

  const renderReplies = (replies, commentIndex) => {
    if (activeCommentIndex !== commentIndex) return null; // Only render replies for the active comment
    return replies.map((reply, index) => (
      <div key={index} className="reply">
        <p><strong>Reply:</strong> {reply.reply}</p>
        <p><small>{new Date(reply.dateTime).toLocaleString()}</small></p>
      </div>
    ));
  };

  const renderComments = (comments) => {
    return comments.map((comment, index) => (
      <div key={index} className="comment">
        <div className="comment-header">
          <div className="user-avatar">A</div> {/* You can replace with user icon or avatar */}
          <div className="comment-info">
            <p><strong>User</strong></p> {/* Replace with dynamic user name */}
            <p><small>{new Date(comment.dateTime).toLocaleString()}</small></p>
          </div>
        </div>
        <p>{comment.comment}</p>
        <button
          className="toggle-replies-btn"
          onClick={() => toggleReplies(index)}
        >
          {activeCommentIndex === index ? 'Hide Replies' : 'Show Replies'}
        </button>
        {comment.replies && comment.replies.length > 0 && (
          <div className="replies">
            {renderReplies(comment.replies, index)}
          </div>
        )}
      </div>
    ));
  };

  if (error) {
    return (
      <div className="error-message">
        <p>{error}</p>
        <button onClick={closePopup} className="btn-close-sb">Close</button>
      </div>
    );
  }

  return (
    <div className="show-blog-popup">
      <div className="popup-inner-sb">
        {blog ? (
          <>
            <h2 className="blog-title">{blog.blogTitle}</h2>
            <p className="blog-description">{blog.blogDescription}</p>
            <p className="publish-date"><strong>Published On:</strong> {new Date(blog.dateTime).toLocaleString()}</p>
            
            <div className="comments-section">
              <h3>Comments:</h3>
              {blog.comments && blog.comments.length > 0 ? (
                renderComments(blog.comments)
              ) : (
                <p>No comments yet.</p>
              )}
            </div>

            <button onClick={closePopup} className="btn-close-sb">Close</button>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

export default Showblog;
